import { Container } from "react-grid-system";
import { useMediaQuery } from "react-responsive";

import {
  Button,
  Card,
  Link as DSLink,
  Heading,
  Icon,
  Span,
  SpotIcon,
} from "@icg360/design-system";

import { BaseLayout } from "components/base-layout";
import { Stack } from "components/common/stack";
import { screenSizes } from "consts";
import { useMultiStepFormState } from "hooks/use-multi-step-form-state";

import { TingContext, type TingContextState } from "../ting-flow";
import styles from "./ting-landing.module.scss";

export const TingLanding = () => {
  const { formNavigate } = useMultiStepFormState<TingContextState>(TingContext);

  const isLaptop = useMediaQuery({ maxWidth: screenSizes.laptopL });
  const isMobile = useMediaQuery({ maxWidth: screenSizes.tablet });

  const quoteText =
    "I received a call from the Ting First Safety Team who discerned our water heater had a leak that was hitting the electrical part on the front of the water heater. As stressful as it was, Ting made the whole process a good experience! They are a lifesaver!";

  return (
    <BaseLayout hideFooter>
      <Container>
        <Stack>
          <Card className={styles.sectionWrapperDS}>
            <Stack
              gap={isLaptop ? "xl" : "2xl"}
              className={styles.introContent}
            >
              <Heading size={isLaptop && !isMobile ? "md" : "lg"}>
                Help protect your home and family from electrical fires with
                Ting
              </Heading>
              <Stack gap={isLaptop ? "md" : "2xl"}>
                <Heading size="md">How do I get started?</Heading>
                <ol className={styles.steps}>
                  <li className={styles.listItem}>
                    <div className={styles.stepNumber}>
                      <Heading size="sm">1</Heading>
                    </div>
                    Enroll to receive your free Ting Sensor
                  </li>
                  <li className={styles.listItem}>
                    <div className={styles.stepNumber}>
                      <Heading size="sm">2</Heading>
                    </div>
                    Set Ting up in just two minutes
                  </li>
                  <li className={styles.listItem}>
                    <div className={styles.stepNumber}>
                      <Heading size="sm">3</Heading>
                    </div>
                    Enjoy peace of mind
                  </li>
                </ol>
              </Stack>
              <div className={styles.desktopButton}>
                <Button
                  onPress={() => {
                    formNavigate("policy-confirmation");
                  }}
                >
                  Get Ting for free
                </Button>
              </div>
            </Stack>
            <div
              className={styles.imageWrapper}
              role="img"
              aria-label="Ting sensor next to an iphone displaying the Ting hazard monitoring app"
            ></div>
          </Card>
          <Card className={styles.tingHowDS}>
            <iframe
              src="https://player.vimeo.com/video/354300475?h=0808bd9bf2&color=f0c319&title=0&byline=0&portrait=0"
              allow="fullscreen; picture-in-picture"
              title="vimeo video player"
              className={styles.video}
            />
            <Stack className={styles.howWrapper}>
              <Heading size={isLaptop ? "sm" : "lg"}>
                How Ting helps protect your home
              </Heading>
              <ul className={styles.howList}>
                <li className={styles.listItem}>
                  <div className={styles.circleCheckWrapper}>
                    <SpotIcon name="CheckCircle" size="xs" />
                  </div>
                  <Span size={isLaptop ? "sm" : "default"}>
                    Ting Plug-In Sensor monitors your home&apos;s electrical
                    system
                  </Span>
                </li>
                <li className={styles.listItem}>
                  <div className={styles.circleCheckWrapper}>
                    <SpotIcon name="CheckCircle" size="xs" />
                  </div>
                  <Span size={isLaptop ? "sm" : "default"}>
                    Track your home&apos;s electrical safety in real-time with
                    our smart sensor and app
                  </Span>
                </li>
                <li className={styles.listItem}>
                  <div className={styles.circleCheckWrapper}>
                    <SpotIcon name="CheckCircle" size="xs" />
                  </div>
                  <Span size={isLaptop ? "sm" : "default"}>
                    $1,000 lifetime labor repair credit included to cover
                    Ting-detected fire hazard remediation
                  </Span>
                </li>
              </ul>
            </Stack>
          </Card>
          <Card className={styles.quoteCardDS}>
            <Stack gap="2xl">
              <Heading size="lg">What Ting customers are saying</Heading>
              <div className={styles.quoteWrapper}>
                <div>
                  <div className={styles.quoteIconWrapper}>
                    <Icon name="Talk" size="md" />
                  </div>
                </div>
                <Stack gap="lg">
                  {isMobile ? (
                    <Span color="inverse">{quoteText}</Span>
                  ) : (
                    <Heading size="msRegular">{quoteText}</Heading>
                  )}
                  <div>- Angela</div>
                </Stack>
              </div>
            </Stack>
          </Card>
          <div className={styles.notificationDisclaimer}>
            <Span color="quiet">
              Ting is not an emergency notification system or any fire or smoke
              detector. Have more questions? Check out{" "}
              <DSLink href="https://sagesure.com/ting/" target="_blank">
                Ting&apos;s FAQs
              </DSLink>
            </Span>
          </div>
        </Stack>
      </Container>
      <Stack gap="md" className={styles.mobileFooter}>
        <Button
          onPress={() => {
            formNavigate("policy-confirmation");
          }}
          fluid
        >
          Get Ting for free
        </Button>
      </Stack>
    </BaseLayout>
  );
};
